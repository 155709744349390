export const PushEndpoints = {
  subscribeToNotifications: "/:userId/notification/subscription",
  unsubscribeFromNotifications: "/:userId/notification/subscription/:endpoint",
  subscribeToMobileNotifications: "/:userId/notification/mobile-subscription",
  unsubscribeFromMobileNotifications:
    "/:userId/notification/mobile-unsubscribe",
  notifications: "/:userId/notification",
  updateNotifications: "/:userId/notification/:notifId",
  websocketToken: "/:userId/websocket/token",
};
