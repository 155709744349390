import CFetch from "../fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const acceptBooking = (
  userId: string,
  { bookingId }: { bookingId: string }
) =>
  CFetch.callAPI(
    endpoints.bookingAccept.buildWithParams({ id: userId, bookingId }),
    {
      method: "PUT",
      body: {
        bookingId,
      },
    }
  );

export const rejectBooking = (
  userId: string,
  { bookingId }: { bookingId: string }
) =>
  CFetch.callAPI(
    endpoints.bookingReject.buildWithParams({ id: userId, bookingId }),
    {
      method: "PUT",
      body: {
        bookingId,
      },
    }
  );
