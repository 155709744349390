import Dinero from "dinero.js";

export const getBookingAmount = (booking, user) => {
  if (user.userType === "CLIENT") {
    const amount = booking.cost;
    const charge = Dinero({ amount }).percentage(15);

    return Dinero({ amount }).add(charge).toFormat();
  }

  const amount = booking.cost;

  const charge = Dinero({ amount }).percentage(15);

  let carerAmount = Dinero({ amount });

  const totalAmount = Dinero({ amount }).add(charge);

  /**
   * Now subtract the charge from carer amount.
   * Stripe charge is 1.4% + 20p (30/05/2019)
   */

  // Get 1.4% of total amount
  const stripePercentAmount = totalAmount.percentage(1.4);

  // add 20p to it
  const stripeChargeAmount = stripePercentAmount.add(Dinero({ amount: 20 }));

  // Subtract the stripe charges
  carerAmount = carerAmount.subtract(stripeChargeAmount);

  return carerAmount.toFormat();
};
