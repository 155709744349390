import CFetch from "./fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { PushEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof PushEndpoints>(
  PushEndpoints,
  import.meta.env.VUE_API_PUSH_URL as string
).get();

export const subscribeToPush = (
  userId: string,
  subscription: PushSubscription
) => {
  return CFetch.callAPI(
    endpoints.subscribeToNotifications.buildWithParams({ userId }),
    {
      method: "POST",
      body: {
        subscription,
      },
    }
  );
};

export const unsubscribeFromPush = (
  userId: string,
  subscription: PushSubscription
) => {
  return CFetch.callAPI(
    endpoints.unsubscribeFromNotifications.buildWithParams({
      userId,
    }),
    {
      method: "PATCH",
      body: {
        subscription,
      },
    }
  );
};

export const getAllNotifications = (userId: string) => {
  return CFetch.callAPI(
    endpoints.notifications.buildWithParams({
      userId,
    })
  );
};

export const dismissNotification = (userId: string, notifId: string) => {
  return CFetch.callAPI(
    endpoints.updateNotifications.buildWithParams({
      userId,
      notifId,
    }),
    { method: "PATCH" }
  );
};

export const dismissAllNotifications = (userId: string) => {
  return CFetch.callAPI(
    endpoints.notifications.buildWithParams({
      userId,
    }),
    { method: "PATCH" }
  );
};
