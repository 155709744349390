export const processAPIErrors = (data, status) => {
  if (status !== 200 && data.status === "Unauthorized") {
    return {
      msg: data.error.message,
      status,
    };
  }

  if (data.errors) {
    // eslint-disable-next-line
    return {
      form: data.errors,
      status,
    };
  }

  if (data.error && data.error.message) {
    // eslint-disable-next-line
    return {
      msg: data.error.message,
      status,
    };
  }
};

export const hasAPIErrors = (data) => data.error || data.errors;
