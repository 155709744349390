import { JWTPayload } from "@urbancarecommunity/commons/@types";
import jwtDecode from "jwt-decode";

export const getDecodedToken = (
  token: string | null
): JWTPayload | undefined => {
  if (!token) {
    return;
  }

  try {
    return jwtDecode(token) as JWTPayload;
  } catch (err) {
    //
  }
};
