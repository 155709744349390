export const PaymentEndpoints = {
  makeManualPayment: "/manual",
  confirmManualPayIntent: "/manual/confirm-pay-intent/:id",
  cancelManualPayIntent: "/manual/cancel-pay-intent",
  getManualPaymentHistory: "/manual/history",

  addPaymentMethod: "/methods",
  getPaymentMethods: "/methods",
  deletePaymentMethods: "/methods/:id",
};
