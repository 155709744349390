import Vue from "vue";
import {
  parseTime,
  getAvatar,
  parseDate,
  isSomething,
  getError,
  getFormError,
  isArray,
  getBookingAmount,
  bytesToSize,
  truncateFileName,
  getFileExtension,
  truncate,
  getShiftDuration,
  getTaskLabelForSummary,
  getShiftTime,
  getShiftDate,
} from "./helpers";

import { formatDate, filterNull, capitalize } from "./helpers/formatter";

// To reduce the size of the chunk
import(/* webpackChunkName: "moment" */ "moment").then(
  ({ default: moment }) => {
    Vue.prototype.$moment = moment;
  }
);

// To reduce the size of the chunk
import(/* webpackChunkName: "dinero" */ "dinero.js").then(
  ({ default: dinero }) => {
    dinero.defaultCurrency = "GBP";
    Vue.prototype.$dinero = dinero;
  }
);

Vue.prototype.$parseTime = parseTime;
Vue.prototype.$parseDate = parseDate;
Vue.prototype.$getAvatar = getAvatar;
Vue.prototype.$isSomething = isSomething;
Vue.prototype.$getError = getError;
Vue.prototype.$getFormError = getFormError;
Vue.prototype.$formatDate = formatDate;
Vue.prototype.$filterNull = filterNull;
Vue.prototype.$capitalize = capitalize;
Vue.prototype.$isArray = isArray;
Vue.prototype.$getBookingAmount = getBookingAmount;
Vue.prototype.$bytesToSize = bytesToSize;
Vue.prototype.$truncateFileName = truncateFileName;
Vue.prototype.$truncate = truncate;
Vue.prototype.$getFileExtension = getFileExtension;
Vue.prototype.$getTaskLabelForSummary = getTaskLabelForSummary;
Vue.prototype.$getShiftDuration = getShiftDuration;
Vue.prototype.$getShiftTime = getShiftTime;
Vue.prototype.$getShiftDate = getShiftDate;
