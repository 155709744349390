import mime from "mime-types";

export const getFileExtension = (type) => {
  return mime.extension(type);
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + " " + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

export const truncateFileName = (str) => {
  const firstCharCount = 15;
  const dotCount = 3;
  const endCharCount = 10;

  if (str.length < firstCharCount + endCharCount) {
    return str;
  }

  let convertedStr = "";

  convertedStr += str.substring(0, firstCharCount);
  convertedStr += ".".repeat(dotCount);
  convertedStr += str.substring(str.length - endCharCount, str.length);
  return convertedStr;
};
