export default {
  name: "useRegisterSW",
  data() {
    return {
      updateSW: undefined,
      offlineReady: false,
      needRefresh: false,
      refreshing: false,
      swRegistration: undefined,
    };
  },
  async mounted() {
    try {
      const { registerSW } = await import("virtual:pwa-register");
      const vm = this;
      this.updateSW = registerSW({
        immediate: true,
        onOfflineReady() {
          vm.offlineReady = true;
          vm.onOfflineReadyFn();
        },
        onNeedRefresh() {
          vm.needRefresh = true;
          vm.onNeedRefreshFn();
        },
        async onRegistered(swRegistration) {
          vm.swRegistration = swRegistration;

          swRegistration && vm.handleSWPushManager?.(swRegistration);
          swRegistration && vm.handleSWManualUpdates?.(swRegistration);
        },
        onRegisterError(e) {
          vm.handleSWRegisterError(e);
        },
      });
    } catch {
      console.error("PWA disabled.");
    }
  },
  methods: {
    async closePromptUpdateSW() {
      this.offlineReady = false;
      this.needRefresh = false;
    },
    onOfflineReadyFn() {
      console.info("[PWA] Offline Ready");
    },
    onNeedRefreshFn() {
      console.info("[PWA] Needs refresh");
    },
    updateServiceWorker() {
      this.refreshing = true;
      this.updateSW && this.updateSW(true);
    },
    handleSWRegisterError(error) {
      console.error(error);
    },
  },
};
