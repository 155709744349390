export const formatDate = (date) => {
  if (!date) return null;
  const newDate = new Date(date).toLocaleString().split(",");
  if (newDate) {
    const [day, month, year] = newDate[0].split("/");
    return `${day}/${month}/${year}`;
  }
  return "Unknown";
};

/* eslint-disable */
export const filterNull = (obj) => {
  const o = JSON.parse(JSON.stringify(obj)); // Clone source oect.

  Object.keys(o).forEach((key) => {
    if (o[key] && typeof o[key] === 'object') { o[key] = filterNull(o[key]); } // Recurse.
    else if (o[key] === undefined || o[key] === null) { delete o[key]; } // Delete undefined and null.
    else { o[key] = o[key]; } // Copy value.
  });

  return o; // Return new object.
};

/* eslint-enable */

export const capitalize = (str) => {
  if (!str) {
    return "";
  }
  const newStr = str.toLowerCase();
  return newStr.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const boolToEng = (bool) => (bool === true ? "Yes" : "No");

export const formatLanguages = (langs) =>
  langs.map((lang) => `${lang.lang}: ${capitalize(lang.fluency)}`);

export const truncate = (str, n) => {
  if (!str) {
    return "";
  }
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

const getYear = (i) => `${i !== 0 ? `${i} year${i > 1 ? "s" : ""}, ` : ""}`;

const getMonth = (i) => `${i !== 0 ? `${i} month${i > 1 ? "s" : ""}` : ""}`;

const getNoExp = (we) =>
  we.year === 0 && we.month === 0 ? "Less than a month" : "";

export const formatWorkExperiences = (wes) => {
  if (wes.length === 0) {
    return "";
  }
  return wes.map(
    (we) =>
      `${we.jobType} (${getYear(we.year)}${getMonth(we.month)}${getNoExp(we)})`
  );
};

export const exists = (item) => {
  if (item === null) {
    return false;
  }

  if (typeof item === "undefined") {
    return false;
  }

  if (Array.isArray(item) && item.length === 0) {
    return false;
  }

  return true;
};
