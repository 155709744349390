import mongoose from "mongoose";
import { AvatarSchema } from "./avatar";
import { GENDERS } from "../user";

export const ServiceUserInfoSchema = new mongoose.Schema({
  _id: mongoose.Schema.Types.ObjectId,
  firstName: {
    type: String,
  },
  lastName: {
    type: String,
  },
  // Once Job Post Address is fixed uncomment it upon requirement
  // address: {
  //   _id: { type: mongoose.Schema.Types.ObjectId },
  //   city: { type: String },
  //   postCode: { type: String }
  // },
  serviceType: {
    type: [String],
    required: true,
  },
  gender: {
    type: String,
    required: true,
    enum: GENDERS,
  },
  note: {
    type: String,
  },
  avatar: AvatarSchema,
  rating: {
    type: Number,
    default: null,
  },
});
