import { isArray } from "./any";

export const getFormError = (errors, string) => {
  if (!isArray(errors)) {
    return true;
  }
  for (let i = 0; i < errors.length; i += 1) {
    const e = errors[i];
    if (e.param === string) {
      return e.msg;
    }
  }
  return true;
};

export const getError = (err) => {
  if (err.msg) {
    return err.msg;
  }

  if (err.form) {
    return err.form;
  }

  return "Could not contact the server. Please try again later.";
};

export const handleCustomError = (cError, keys, validationResult) => {
  let cErrorInternal = cError || {};
  const shouldProcessDeletes = cError !== null && cError !== undefined;
  if (typeof validationResult === "string") {
    const parent = keys.slice(0, -1).reduce((acc, key) => {
      acc[key] = acc[key] || {};
      return acc[key];
    }, cErrorInternal);
    parent[keys[keys.length - 1]] = validationResult;
  } else {
    if (shouldProcessDeletes) {
      const parent = keys.slice(0, -1).reduce((acc, key) => {
        return acc && acc[key] ? acc[key] : null;
      }, cErrorInternal);
      if (parent && parent[keys[keys.length - 1]]) {
        delete parent[keys[keys.length - 1]];
      }
      keys.slice(0, -1).reduce((acc, key) => {
        if (acc && acc[key] && Object.keys(acc[key]).length === 0) {
          delete acc[key];
        }
        return acc ? acc[key] : null;
      }, cErrorInternal);
    }
  }
  if (shouldProcessDeletes && Object.keys(cErrorInternal).length === 0) {
    return null;
  }
  return Object.keys(cErrorInternal).length === 0 ? null : cErrorInternal;
};
