import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm", // This is equivalent to a value of 960
  },
  theme: {
    themes: {
      light: {
        primary: "#005fb8",
        secondary: "#d5e3ff",
        background: "#e6eff8",
        navbar: "#e6eff8",
        drawer: "#e6eff8",
        card: "#ffffff",
        event: {
          availability: "#26A69A",
          weeklyBooking: "#29B6F6",
          oneTimeBooking: "#EC407A",
        },
      },
      dark: {
        primary: "#005fb8",
        secondary: "#5b98d2",
        background: "#303030",
        event: {
          availability: "#26A69A",
          weeklyBooking: "#29B6F6",
          oneTimeBooking: "#EC407A",
        },
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
