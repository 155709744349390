import CFetch from "./fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { ClientEndpoints } from "@urbancarecommunity/commons/constants";
import { BookingForm, IReview } from "@urbancarecommunity/commons/@types";
import { IClientDetails } from "@urbancarecommunity/commons/@types";

const endpoints = new APIEndpoints<typeof ClientEndpoints>(
  ClientEndpoints,
  import.meta.env.VUE_API_CLIENT_URL as string
).get();

export const getApplicationStatus = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileAccountStatus.buildWithParams({ id: userId })
  );
};

export const getOnBoardDetails = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileOnboardStatus.buildWithParams({ id: userId })
  );
};

export const searchClients = (paginateOptions: any) => {
  return CFetch.callAPI(endpoints.profileSearch.build(), {
    params: paginateOptions,
  });
};

export const getClientProfile = (userId: string) => {
  return CFetch.callAPI(endpoints.profile.buildWithParams({ id: userId }));
};

export const updateClientProfile = (
  userId: string,
  update: Partial<IClientDetails>
) => {
  return CFetch.callAPI(endpoints.profile.buildWithParams({ id: userId }), {
    method: "PUT",
    body: update,
  });
};

export const getRecentBookings = (userId: string, query: any) => {
  return CFetch.callAPI(
    endpoints.bookingRecent.buildWithParams({ id: userId }, query)
  );
};

export const getRecentServiceUsers = (userId: string) => {
  return CFetch.callAPI(
    endpoints.serviceUserRecent.buildWithParams({ id: userId })
  );
};

export const approveClient = (userId: string, approved: boolean) => {
  return CFetch.callAPI(
    endpoints.profileApprove.buildWithParams({ id: userId }),
    {
      method: "PUT",
      body: {
        approved,
      },
    }
  );
};

export const disableClient = (userId: string) => {
  return CFetch.callAPI(
    endpoints.profileDisabled.buildWithParams({ id: userId }),
    {
      method: "PUT",
    }
  );
};

export const getBookings = (userId: string, params: any) => {
  return CFetch.callAPI(endpoints.booking.buildWithParams({ id: userId }), {
    method: "GET",
    params,
  });
};

export const getBookingsCount = (userId: string, params: any) => {
  return CFetch.callAPI(
    endpoints.bookingCount.buildWithParams({ id: userId }),
    {
      method: "GET",
      params,
    }
  );
};

export const getBookingRequests = (userId: string, options: any) => {
  return CFetch.callAPI(
    endpoints.bookingRequest.buildWithParams({ id: userId }),
    {
      method: "GET",
      params: options,
    }
  );
};

export const getBooking = (userId: string, bookingId: string) =>
  CFetch.callAPI(
    endpoints.bookingId.buildWithParams({ id: userId, bookingId: bookingId }),
    {
      method: "GET",
    }
  );

export const acceptBooking = (
  userId: string,
  { bookingId }: { bookingId: string }
) =>
  CFetch.callAPI(
    endpoints.bookingAccept.buildWithParams({ id: userId, bookingId }),
    {
      method: "PUT",
      body: {
        bookingId,
      },
    }
  );

export const rejectBooking = (
  userId: string,
  { bookingId }: { bookingId: string }
) =>
  CFetch.callAPI(
    endpoints.bookingReject.buildWithParams({ id: userId, bookingId }),
    {
      method: "PUT",
      body: {
        bookingId,
      },
    }
  );

export const getBookingCost = (userId: string, bookingId: string) =>
  CFetch.callAPI(
    endpoints.bookingCost.buildWithParams({ id: userId, bookingId })
  );

export const replaceCarer = (userId: string, bookingId: string, shifts: []) =>
  CFetch.callAPI(
    endpoints.bookingReplaceCarer.buildWithParams({
      id: userId,
      bookingId,
    }),
    {
      method: "PUT",
      body: {
        shifts,
      },
    }
  );

export const getShift = (userId: string, shiftId: string) =>
  CFetch.callAPI(
    endpoints.bookingShiftId.buildWithParams({ id: userId, shiftId: shiftId }),
    {
      method: "GET",
    }
  );

export const cancelBooking = (
  userId: string,
  bookingId: string,
  { reason, warning }: any
) =>
  CFetch.callAPI(
    endpoints.bookingCancel.buildWithParams({
      id: userId,
      bookingId: bookingId,
    }),
    {
      method: "PUT",
      body: {
        reason,
        warning,
      },
    }
  );

export const reviewCarers = (
  userId: string,
  bookingId: string,
  reviewDetail: IReview[]
) => {
  return CFetch.callAPI(
    endpoints.bookingReviewId.buildWithParams({
      id: userId,
      bookingId: bookingId,
    }),
    {
      method: "PUT",
      body: reviewDetail,
    }
  );
};

export const cancelShift = (
  userId: string,
  shiftId: string,
  { reason, warning }: any
) =>
  CFetch.callAPI(
    endpoints.bookingCancelShift.buildWithParams({
      id: userId,
      shiftId: shiftId,
    }),
    {
      method: "PUT",
      body: {
        shiftId,
        reason,
        warning,
      },
    }
  );

export const reviewTasks = (
  userId: string,
  bookingId: string,
  shiftId: string,
  tasks: {
    _id: string;
    carers: string[];
  }[]
) => {
  return CFetch.callAPI(
    endpoints.bookingShiftClientReviewTasks.buildWithParams({
      id: userId,
      bookingId,
      shiftId,
    }),
    {
      method: "PUT",
      body: { tasks },
    }
  );
};

export const getBookingPreview = (userId: string, bookingDetails: any) =>
  CFetch.callAPI(endpoints.bookingPreview.buildWithParams({ id: userId }), {
    method: "POST",
    body: bookingDetails,
  });

export const checkAvailability = (userId: string, bookingDetails: any) =>
  CFetch.callAPI(
    endpoints.bookingCheckAvailability.buildWithParams({ id: userId }),
    {
      method: "POST",
      body: bookingDetails,
    }
  );

export const makeBooking = (userId: string, bookingForm: BookingForm) =>
  CFetch.callAPI(endpoints.makeBooking.build(), {
    method: "POST",
    body: bookingForm,
  });

export const checkBookingEditable = (userId: string, bookingId: string) =>
  CFetch.callAPI(
    endpoints.bookingCheckEditable.buildWithParams({ id: userId, bookingId }),
    {
      method: "GET",
    }
  );

export const getTaskLists = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.taskList.buildWithParams({
      id: userId,
      serviceUserId,
    })
  );
};

export const getTaskList = (
  userId: string,
  serviceUserId: string,
  taskListsId: string
) => {
  return CFetch.callAPI(
    endpoints.taskListById.buildWithParams({
      id: userId,
      serviceUserId,
      taskListsId,
    })
  );
};

export const addTaskList = (
  userId: string,
  serviceUserId: string,
  taskData: {
    name: string;
    description: string;
    tasks: [];
  }
) =>
  CFetch.callAPI(
    endpoints.taskList.buildWithParams({
      id: userId,
      serviceUserId,
    }),
    {
      method: "POST",
      body: taskData,
    }
  );

export const editTaskList = (
  userId: string,
  serviceUserId: string,
  taskListsId: string,
  taskData: {
    name: string;
    description: string;
    tasks: [];
  }
) =>
  CFetch.callAPI(
    endpoints.taskListById.buildWithParams({
      id: userId,
      serviceUserId,
      taskListsId,
    }),
    {
      method: "PUT",
      body: taskData,
    }
  );

export const deleteTaskList = (
  userId: string,
  serviceUserId: string,
  taskListsId: string
) =>
  CFetch.callAPI(
    endpoints.taskListById.buildWithParams({
      id: userId,
      serviceUserId,
      taskListsId,
    }),
    {
      method: "DELETE",
    }
  );

export const getAddresses = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.address.buildWithParams({
      id: userId,
      serviceUserId,
    })
  );
};

export const getAddressById = (
  userId: string,
  serviceUserId: string,
  addressId: string
) => {
  return CFetch.callAPI(
    endpoints.addressId.buildWithParams({
      id: userId,
      serviceUserId,
      addressId,
    })
  );
};

export const setDefaultAddress = (
  userId: string,
  serviceUserId: string,
  addressId: string
) => {
  return CFetch.callAPI(
    endpoints.addressId.buildWithParams({
      id: userId,
      serviceUserId,
      addressId,
    }),
    {
      method: "POST",
    }
  );
};

export const addAddress = (
  userId: string,
  serviceUserId: string,
  formData: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
    mobile: string;
    landline: string;
  }
) => {
  return CFetch.callAPI(
    endpoints.address.buildWithParams({
      id: userId,
      serviceUserId: serviceUserId,
    }),
    {
      method: "POST",
      body: formData,
    }
  );
};

export const editAddress = (
  userId: string,
  serviceUserId: string,
  addressId: string,
  formData: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    postCode: string;
    mobile: string;
    landline: string;
  }
) => {
  return CFetch.callAPI(
    endpoints.addressId.buildWithParams({
      id: userId,
      serviceUserId: serviceUserId,
      addressId: addressId,
    }),
    {
      method: "PUT",
      body: formData,
    }
  );
};

export const deleteAddress = (
  userId: string,
  serviceUserId: string,
  addressId: string
) => {
  return CFetch.callAPI(
    endpoints.addressId.buildWithParams({
      id: userId,
      serviceUserId,
      addressId,
    }),
    {
      method: "DELETE",
    }
  );
};

export const saveNote = (
  userId: string,
  serviceUserId: string,
  note: string
) => {
  return CFetch.callAPI(
    endpoints.serviceUserNote.buildWithParams({
      id: userId,
      serviceUserId: serviceUserId,
    }),
    {
      method: "PUT",
      body: { note },
    }
  );
};

// TODO check below functions are required?
export const uploadServiceUserAvatar = () => {};

export const deleteServiceUser = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.serviceUserId.buildWithParams({
      id: userId,
      serviceUserId,
    }),
    {
      method: "DELETE",
    }
  );
};

export const getPin = () => {};

export const setupPin = () => {};

export const pay = (userId: string, paymentDetails: any) => {
  return CFetch.callAPI(
    endpoints.pay.buildWithParams({
      id: userId,
    }),
    {
      method: "PUT",
      body: paymentDetails,
    }
  );
};

export const payManual = (userId: string, paymentDetails: any) => {
  return CFetch.callAPI(
    endpoints.payManual.buildWithParams({
      id: userId,
    }),
    {
      method: "PUT",
      body: paymentDetails,
    }
  );
};

export const getManualTransactionHistory = (userId: string) => {
  return CFetch.callAPI(
    endpoints.payManualHistory.buildWithParams({
      id: userId,
    })
  );
};
export const getJobPosts = (userId: string) => {
  return CFetch.callAPI(
    endpoints.jobPost.buildWithParams({
      id: userId,
    })
  );
};

export const getJobPostById = (userId: string, jobPostId: string) => {
  return CFetch.callAPI(
    endpoints.jobPostId.buildWithParams({
      id: userId,
      jobPostId,
    })
  );
};

export const getJobPostShift = (
  userId: string,
  jobPostId: string,
  jobPostShiftId: string
) => {
  return CFetch.callAPI(
    endpoints.jobPostShiftId.buildWithParams({
      id: userId,
      jobPostId,
      jobPostShiftId,
    })
  );
};

export const jobPostPreview = (userId: string, jobPostData: any) => {
  return CFetch.callAPI(
    endpoints.jobPostPreview.buildWithParams({
      id: userId,
    }),
    {
      method: "POST",
      body: jobPostData,
    }
  );
};

export const jobPostPreviewById = (
  userId: string,
  jobPostId: string,
  jobPostData: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostPreview.buildWithParams({
      id: userId,
      jobPostId,
    }),
    {
      method: "POST",
      body: jobPostData,
    }
  );
};
export const jobPostSave = (userId: string, jobPostData: any) => {
  return CFetch.callAPI(
    endpoints.jobPost.buildWithParams({
      id: userId,
    }),
    {
      method: "POST",
      body: jobPostData,
    }
  );
};

export const jobPostAccept = (
  userId: string,
  jobPostId: string,
  carers: any,
  role: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostAccept.buildWithParams({
      id: userId,
      jobPostId,
    }),
    {
      method: "PUT",
      body: { carers, role },
    }
  );
};

export const jobPostReject = (
  userId: string,
  jobPostId: string,
  carers: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostReject.buildWithParams({
      id: userId,
      jobPostId,
    }),
    {
      method: "PUT",
      body: { carers },
    }
  );
};

export const jobPostShiftAccept = (
  userId: string,
  jobPostId: string,
  jobPostShiftId: string,
  carers: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostShiftAccept.buildWithParams({
      id: userId,
      jobPostId,
      jobPostShiftId,
    }),
    {
      method: "PUT",
      body: { carers },
    }
  );
};

export const jobPostShiftReject = (
  userId: string,
  jobPostId: string,
  jobPostShiftId: string,
  carers: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostShiftReject.buildWithParams({
      id: userId,
      jobPostId,
      jobPostShiftId,
    }),
    {
      method: "PUT",
      body: { carers },
    }
  );
};

export const jobPostCancel = (
  userId: string,
  jobPostId: string,
  payload: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostCancel.buildWithParams({
      id: userId,
      jobPostId,
    }),
    {
      method: "POST",
      body: payload,
    }
  );
};

export const jobPostToBooking = (
  userId: string,
  jobPostId: string,
  jobPost: any
) => {
  return CFetch.callAPI(
    endpoints.jobPostToBooking.buildWithParams({
      id: userId,
      jobPostId,
    }),
    {
      method: "POST",
      body: { jobPost },
    }
  );
};

export const getOpenJobPosts = () => {
  return CFetch.callAPIWithoutAuth(endpoints.openJobPost.buildWithParams({}));
};

export const getOpenJobPostById = (jobPostId: string) => {
  return CFetch.callAPIWithoutAuth(
    endpoints.openJobPostById.buildWithParams({ jobPostId })
  );
};

export const makePaymentInCash = (userId: string, bookingId: string) => {
  return CFetch.callAPI(
    endpoints.payInCash.buildWithParams({
      id: userId,
    }),
    {
      method: "PUT",
      body: { bookingId },
    }
  );
};

export * from "./client/monitoring";
export * from "./client/shift";
export * from "./client/serviceUsers";
export * from "./client/payment";
