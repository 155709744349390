import mongoose from "mongoose";

export const CarerInfo = new mongoose.Schema(
  {
    _id: mongoose.Schema.Types.ObjectId,
    role: mongoose.Schema.Types.ObjectId,
    firstName: String,
    lastName: String,
    city: String,
    languages: [
      {
        lang: {
          type: String,
          index: true,
          text: true,
        },
        fluency: {
          type: String,
        },
      },
    ],
    avatar: {
      xSmall: {
        key: String,
        url: String,
      },
      small: {
        key: String,
        url: String,
      },
      medium: {
        key: String,
        url: String,
      },
      large: {
        key: String,
        url: String,
      },
    },
    status: String,
  },
  { _id: false }
);
