import mongoose from "mongoose";

export enum IUserType {
  CARER = "CARER",
  CLIENT = "CLIENT",
  ADMIN = "ADMIN",
}

export enum IClientType {
  ORGANIZATION = "ORGANISATION",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum ICarerType {
  Carer = "Carer",
  QMO = "QMO",
}

export interface IUser extends mongoose.Document {
  createdAt: Date;
  updatedAt: Date;
  email: string;
  userType: IUserType;
  clientType: IClientType;
  carerType: ICarerType;
  openJobPostId: string;
  mfa: boolean;
}

export interface IUserInfo {
  _id: string;
  email: string;
  userType: IUserType;
  clientType: IClientType;
}
