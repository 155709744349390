export const ClientEndpoints = {
  profile: "/:id/profile",
  profileApprove: "/:id/profile/approve",
  profileDisabled: "/:id/profile/disabled",
  profileSearch: "/search",
  profileAccountStatus: "/:id/profile/account-status",
  profileAvatar: "/:id/profile/avatar",
  profileOnboardStatus: "/:id/profile/onboard-status",
  fileUploadAvatar: "/:id/upload/avatar",
  makeBooking: "/booking",
  booking: "/:id/booking",
  bookingCount: "/:id/booking-count",
  bookingId: "/:id/booking/:bookingId",
  bookingRequest: "/:id/booking/request",
  bookingCheckAvailability: "/:id/booking/check-availability",
  bookingPreview: "/:id/booking/preview",
  bookingRecent: "/:id/booking/recent",
  bookingAccept: "/:id/booking/:bookingId/accept",
  bookingReject: "/:id/booking/:bookingId/reject",
  bookingCost: "/:id/booking/:bookingId/cost",
  bookingReplaceCarer: "/:id/booking/:bookingId/replace-carer",
  bookingCheckEditable: "/:id/booking/:bookingId/check-editable",
  bookingShiftReview: "/:id/booking/shift/review",
  bookingShiftClockIn: "/:id/booking/:bookingId/shift/:shiftId/clock-in",
  bookingShiftClockOut: "/:id/booking/:bookingId/shift/:shiftId/clock-out",
  bookingReviewId: "/:id/booking/:bookingId/review",
  bookingCancel: "/:id/booking/:bookingId/cancel",
  bookingShift: "/:id/booking/shift",
  bookingShiftId: "/:id/booking/shift/:shiftId",
  bookingShiftCarerReviewTasks:
    "/:id/booking/:bookingId/shift/:shiftId/carer-review-tasks",
  bookingShiftClientReviewTasks:
    "/:id/booking/:bookingId/shift/:shiftId/client-review-tasks",
  bookingCancelShift: "/:id/booking/shift/:shiftId/cancel",
  pay: "/:id/pay",
  confirmPay: "/:id/pay/:bookingId/check-payment",
  payManual: "/:id/payManual",
  payManualHistory: "/:id/payment/manual/history",
  payInCash: "/:id/payInCash",
  payMethods: "/:id/pay/methods",
  payMethodId: "/:id/pay/methods/:cardId",
  serviceUser: "/:id/service-user",
  serviceUserRecent: "/:id/service-user/recent",
  serviceUserId: "/:id/service-user/:serviceUserId",
  serviceUserNote: "/:id/service-user/:serviceUserId/note",
  serviceAvatar: "/:id/service-user/:serviceUserId/avatar",
  address: "/:id/service-user/:serviceUserId/address",
  addressId: "/:id/service-user/:serviceUserId/address/:addressId",
  taskList: "/:id/service-user/:serviceUserId/task-lists",
  taskListById: "/:id/service-user/:serviceUserId/task-lists/:taskListsId",
  jobPost: "/:id/job-post",
  jobPostId: "/:id/job-post/:jobPostId",
  jobPostToBooking: "/:id/job-post/to-booking/:jobPostId",
  jobPostPreview: "/:id/job-post/preview",
  jobPostSave: "/:id/job-post/save",
  jobPostCancel: "/:id/job-post/:jobPostId/cancel",
  jobPostAccept: "/:id/job-post/:jobPostId/accept",
  jobPostReject: "/:id/job-post/:jobPostId/reject",
  jobPostShiftId: "/:id/job-post/:jobPostId/shift/:jobPostShiftId",
  jobPostShiftAccept: "/:id/job-post/:jobPostId/shift/:jobPostShiftId/accept",
  jobPostShiftReject: "/:id/job-post/:jobPostId/shift/:jobPostShiftId/reject",
  job: "/job",
  openJobPost: "/openJobPost",
  openJobPostById: "/openJobPost/:jobPostId",
  monitoring: "/:id/booking/:bookingId/shift/:shiftId/monitoring",
  monitoringAll: "/:id/monitoring",
  monitoringUpdate:
    "/:id/booking/:bookingId/shift/:shiftId/monitoring/:monitoringId",
  monitoringDownloadLink:
    "/:id/booking/:bookingId/shift/:shiftId/monitoring/:monitoringId/download",

  chatBot: "/chatBot",
};
