import { ClientEndpoints } from "@urbancarecommunity/commons/constants";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import CFetch from "../fetch";

const endpoints = new APIEndpoints<typeof ClientEndpoints>(
  ClientEndpoints,
  import.meta.env.VUE_API_CLIENT_URL as string
).get();

export const getMonitoringDocumentsForShift = (
  userId: string,
  bookingId: string,
  shiftId: string
) => {
  return CFetch.callAPI(
    endpoints.monitoring.buildWithParams({
      id: userId,
      shiftId,
      bookingId,
    }),
    {
      method: "GET",
    }
  );
};

export const addMonitoringDocument = (
  userId: string,
  bookingId: string,
  shiftId: string,
  file: File
) => {
  const data = new FormData();
  data.append("document", file);

  return CFetch.callAPI(
    endpoints.monitoring.buildWithParams({
      id: userId,
      shiftId,
      bookingId,
    }),
    {
      method: "POST",
      body: data,
      fileUpload: true,
    }
  );
};

export const getAllMonitoringDocument = (userId: string) => {
  return CFetch.callAPI(
    endpoints.monitoringAll.buildWithParams({
      id: userId,
    })
  );
};

export const getMonitoringdocumentDownloadLink = (
  userId: string,
  bookingId: string,
  shiftId: string,
  monitoringId: string
) => {
  return CFetch.callAPI(
    endpoints.monitoringDownloadLink.buildWithParams({
      id: userId,
      shiftId,
      bookingId,
      monitoringId,
    })
  );
};
