import { processAPIErrors } from "../utils/errors";
import { authServiceInstance } from "../auth";
import { IdentityAPI } from ".";
import { get, CredentialRequestOptionsJSON } from "@github/webauthn-json";

interface ApiOptions extends Omit<RequestInit, "body"> {
  params?: Record<string, string>;
  body?: unknown;
  fileUpload?: boolean;
}

interface RichAuthApiOptions extends ApiOptions {
  hash: string;
}

export interface APIError {
  form?: unknown;
  msg?: string;
  status?: number;
}

type ExcelBlob = Blob;

export interface APICallResponse<T> {
  data?: T | ExcelBlob;
  error?: APIError;
}

const sanitizeBody = (body: any, skipSanitize = false): any => {
  if (typeof body === "object" && body !== null && !skipSanitize) {
    return Object.entries(body).reduce(
      (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
      {}
    );
  }
  return body;
};

class CFetch {
  private static getURLWithParams = (
    endpoint: RequestInfo,
    params?: Record<string, string>
  ): RequestInfo => {
    const url = new URL(endpoint.toString());
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return url.toString();
  };

  private static processOptions = (options?: ApiOptions): RequestInit => {
    const headers: HeadersInit = new Headers(options?.headers);
    if (!options?.fileUpload) {
      headers.set("Content-Type", "application/json; charset=utf-8");
    }
    let body: BodyInit | unknown | undefined = options?.body;
    if (!options?.fileUpload && options?.body) {
      body = JSON.stringify(sanitizeBody(options.body));
    }

    return {
      ...options,
      headers,
      body: body,
      credentials: "include",
    } as RequestInit;
  };

  private hasAuthHeader = (headers?: Record<string, string>): boolean => {
    const authHeader = headers?.["Authorization"];

    return !!authHeader;
  };

  public callAPIWithoutAuth = async <T>(
    url: RequestInfo,
    options?: ApiOptions
  ): Promise<APICallResponse<T>> => {
    const urlWithParams = CFetch.getURLWithParams(url, options?.params);
    const processedOptions = CFetch.processOptions(options);

    try {
      const res = await fetch(urlWithParams, processedOptions);

      if (
        res.status === 401 &&
        this.hasAuthHeader(options?.headers as Record<string, string>)
      ) {
        authServiceInstance.refreshAccessToken();
      }
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("text/xlsx")) {
        const blobData = await res.blob();
        return { data: blobData, error: undefined };
      } else {
        const data = await res.json();

        const error = processAPIErrors(data, res.status);

        return { data, error };
      }
    } catch (err) {
      import.meta.env.DEV && console.error(err);
      return {
        data: undefined,
        error: { msg: "Something went wrong. Please try again later." },
      };
    }
  };

  public callAPI = async <T>(
    url: RequestInfo,
    options?: ApiOptions
  ): Promise<APICallResponse<T>> => {
    const authHeaders = new Headers(options?.headers);
    authHeaders.set(
      "Authorization",
      `Bearer ${await authServiceInstance.getAuthorizationToken()}`
    );

    return this.callAPIWithoutAuth(url, { ...options, headers: authHeaders });
  };

  public callAPIWithRichAuth = async <T>(
    url: RequestInfo,
    options: RichAuthApiOptions
  ): Promise<APICallResponse<T>> => {
    const fidoOptions = await IdentityAPI.getFidoAuthOptions();
    if (fidoOptions.error || !fidoOptions.data) {
      return {
        error: { msg: "Failed to complete authentication. Please try again." },
      };
    }

    let result = undefined;
    try {
      result = await get(fidoOptions.data as CredentialRequestOptionsJSON);
    } catch (err) {
      return {
        error: { msg: "Failed to complete authentication. Please try again." },
      };
    }

    const authToken = await IdentityAPI.getRichAuthToken(result, options.hash);
    if (authToken.error || !authToken.data) {
      return {
        error: { msg: "Failed to authorise the action. Please try again." },
      };
    }

    const authHeaders = new Headers(options?.headers);
    authHeaders.set("x-rich-auth-token", authToken.data.richAuthToken);

    return this.callAPI(url, { ...options, headers: authHeaders });
  };
}

export default new CFetch();
