import CFetch from "./fetch";
import {
  APIEndpoints,
  PaymentEndpoints,
} from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof PaymentEndpoints>(
  PaymentEndpoints,
  import.meta.env.VUE_API_PAYMENT_URL as string
).get();

export const createManualPayment = (
  userId: string,
  paymentDetails: unknown
) => {
  return CFetch.callAPIWithRichAuth(endpoints.makeManualPayment.build(), {
    method: "PUT",
    body: paymentDetails,
    hash: userId,
  });
};
export const makeManualPayment = (userId: string, paymentDetails: unknown) => {
  return CFetch.callAPIWithRichAuth(endpoints.makeManualPayment.build(), {
    method: "POST",
    body: paymentDetails,
    hash: userId,
  });
};

export const confirmManualPayment = (
  userId: string,
  paymentIntentId: string
) => {
  return CFetch.callAPI(
    endpoints.confirmManualPayIntent.buildWithParams({ id: paymentIntentId })
  );
};

export const cancelManualPayment = (
  userId: string,
  body: { paymentIntentId: string; _id: string }
) => {
  return CFetch.callAPI(endpoints.cancelManualPayIntent.build(), {
    method: "POST",
    body,
  });
};

export const getManualPaymentMethods = () => {
  return CFetch.callAPI(endpoints.makeManualPayment.build(), {
    method: "POST",
  });
};

export const getManualPaymentHistory = (params: any) => {
  return CFetch.callAPI(endpoints.getManualPaymentHistory.build(), {
    method: "GET",
    params: params,
  });
};

export const addPaymentMethod = (token: string, email: string) => {
  return CFetch.callAPI(endpoints.addPaymentMethod.build(), {
    method: "POST",
    body: {
      token,
      email,
    },
  });
};

export const removePaymentMethod = (id: string) => {
  return CFetch.callAPI(
    endpoints.deletePaymentMethods.buildWithParams({ id }),
    {
      method: "POST",
      body: {
        cardId: id,
      },
    }
  );
};

export const getPaymentMethods = () => {
  return CFetch.callAPI(endpoints.getPaymentMethods.build(), {
    method: "GET",
  });
};
