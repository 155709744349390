import Vue from "vue";
import VueRouter from "vue-router";
import {
  AdminAuthGuard,
  AdminLoginGuard,
  MFASetupGuard,
  MFAGuard,
  AdminDashboardGuard,
  AdminLockGuard,
} from "../auth/guard";

Vue.use(VueRouter);

const Lock = () => import("./views/Auth/Lock.vue");
const Dashboard = () => import("./views/Dashboard.vue");
const Home = () => import("./views/Home.vue");
const Users = () => import("./views/Users/Users.vue");
const CareWorkers = () => import("./views/CareWorkers/CareWorkers.vue");
const Apps = () => import("./views/Apps.vue");
const JobRoles = () => import("./views/JobRoles/JobRoles.vue");
const Support = () => import("./views/Support/Support.vue");
const Carer = () => import("./views/CareWorkers/Profile/CarerProfile.vue");
const ClientProfile = () => import("./views/Clients/Profile/ClientProfile.vue");
const BankDetails = () => import("./views/BankDetails.vue");
const ManualPaymentInvoice = () => import("./views/ManualPaymentInvoice.vue");
const ManualPayment = () => import("./views/ManualPayment.vue");
const Settings = () => import("./views/Settings/Settings.vue");
const PaymentMethods = () =>
  import("./views/dashboard/Settings/PaymentMethods.vue");
const BookingHistory = () => import("./views/bookingHistory/index.vue");
const createBooking = () =>
  import("../index/views/client/portal/Book/Index.vue");
const clientBooking = () =>
  import("../index/views/client/portal/booking/Index.vue");
const carerBookings = () => import("../index/views/carer/portal/Job/Index.vue");
const ClientShift = () => import("./views/bookingHistory/ClientShift.vue");
const CarerShift = () => import("./views/bookingHistory/CarerShift.vue");
const Audit = () => import("./views/panel/Audit/index.vue");
const EmailBroadcast = () => import("./views/EmailBroadcast.vue");
const AuthIndex = () => import("./views/Auth/Index.vue");
const Login = () => import("./views/Auth/Login.vue");
const MFA = () => import("./views/Auth/MFA.vue");
const MFASetup = () => import("./views/Auth/MFASetup.vue");
const ClientList = () => import("./views/Clients/ClientList.vue");

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    redirect: "/dashboard/carers",
    beforeEnter: AdminDashboardGuard,
    children: [
      {
        path: "home",
        component: Home,
      },
      {
        path: "carers",
        component: CareWorkers,
        name: "Care Workers",
      },
      {
        path: "clients",
        component: ClientList,
        name: "Clients",
      },
      {
        path: "bank-details",
        component: BankDetails,
        name: "Bank Details",
      },
      {
        path: "users",
        component: Users,
      },
      {
        path: "applications",
        component: Apps,
        name: "Applications",
      },
      {
        path: "job-roles",
        component: JobRoles,
        name: "Pay Rate",
      },
      {
        path: "support",
        component: Support,
        name: "Support",
      },
      {
        path: "carer/profile/:id",
        component: Carer,
      },
      {
        path: "client/profile/:id",
        component: ClientProfile,
      },
      {
        path: "manual-payment",
        component: ManualPayment,
        name: "Manual Payment",
      },
      {
        path: "manual-payment/confirm-pay-intent/:id",
        beforeEnter() {
          window.close();
        },
      },
      {
        path: "manual-payment/invoice",
        component: ManualPaymentInvoice,
      },
      {
        path: "booking-history",
        component: BookingHistory,
        name: "Booking History",
      },
      {
        path: "client/:clientId/booking/:id",
        component: clientBooking,
      },
      {
        path: "carer/:carerId/booking/:id",
        component: carerBookings,
      },
      {
        path: "client/:clientId/booking/create",
        component: createBooking,
      },
      {
        path: "client/:clientId/shift/:id",
        component: ClientShift,
      },
      {
        path: "carer/:carerId/shift/:id",
        component: CarerShift,
      },
      {
        path: "settings",
        component: Settings,
        name: "Settings",
      },
      {
        path: "audit",
        component: Audit,
        name: "Audits",
      },
      {
        path: "email-broadcast",
        component: EmailBroadcast,
        name: "Email Broadcast",
      },
      {
        path: "settings/payment-methods",
        component: PaymentMethods,
      },
    ],
  },
  {
    path: "/",
    component: AuthIndex,
    redirect: "/auth/login",
    beforeEnter: AdminAuthGuard,
    props: { admin: true },
    children: [
      {
        path: "/auth/lock",
        component: Lock,
        beforeEnter: AdminLockGuard,
      },
      {
        path: "/auth/login",
        component: Login,
        beforeEnter: AdminLoginGuard,
      },
      {
        path: "/auth/mfa",
        component: MFA,
        beforeEnter: MFAGuard,
      },
      {
        path: "/auth/mfa-setup",
        component: MFASetup,
        beforeEnter: MFASetupGuard,
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
