import { IDocumentChecklistUpdate } from "@urbancarecommunity/commons/@types";
import CFetch from "../fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const getDocumentChecklists = (userId: string) => {
  return CFetch.callAPI(
    endpoints.documentChecklist.buildWithParams({ id: userId })
  );
};

export const editDocumentChecklists = (
  userId: string,
  documentChecklist: IDocumentChecklistUpdate
) => {
  return CFetch.callAPI(
    endpoints.documentChecklist.buildWithParams({ id: userId }),
    { method: "PUT", body: documentChecklist }
  );
};
