import { ClientEndpoints } from "@urbancarecommunity/commons/constants";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import CFetch from "../fetch";

const endpoints = new APIEndpoints<typeof ClientEndpoints>(
  ClientEndpoints,
  import.meta.env.VUE_API_CLIENT_URL as string
).get();

export const addPaymentMethod = (userId: string, token: string) => {
  return CFetch.callAPI(
    endpoints.payMethods.buildWithParams({
      id: userId,
    }),
    {
      method: "POST",
      body: {
        token,
      },
    }
  );
};

export const getPaymentMethods = (userId: string) => {
  return CFetch.callAPI(endpoints.payMethods.buildWithParams({ id: userId }));
};

export const removePaymentMethod = (userId: string, cardId: string) => {
  return CFetch.callAPI(
    endpoints.payMethodId.buildWithParams({ id: userId, cardId }),
    {
      method: "POST",
      body: {
        cardId,
      },
    }
  );
};
