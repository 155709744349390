export type EndpointDetails = string;

export class APIEndpoint {
  private readonly baseUrl?: string;

  private readonly url: string;

  constructor(url: string, baseUrl?: string) {
    this.baseUrl = baseUrl;
    this.url = url;
  }

  public get = (): string => {
    return this.url;
  };

  public build = (): string => {
    if (!this.baseUrl) {
      throw new Error("Base URL not provided");
    }

    return `${this.baseUrl}${this.url}`;
  };

  public buildWithParams = (
    params: { [key: string]: string },
    queryParams?: { [key: string]: string } | null
  ): string => {
    if (!this.baseUrl) {
      throw new Error("Base URL not provided");
    }

    let urlWithParams = this.url;
    Object.entries(params).forEach(([key, value]) => {
      const paramKey = `:${key}`;

      if (!urlWithParams.includes(paramKey) || !value) {
        throw new Error("Parameters does not exist in the URL");
      }

      urlWithParams = urlWithParams.replace(paramKey, value as string);
    });

    const queryString = new URLSearchParams(queryParams).toString();
    return `${this.baseUrl}${urlWithParams}${
      queryString ? `?${queryString}` : ""
    }`;
  };
}
