import moment from "moment";
import ms from "ms";
import { APIError } from "../api/fetch";
import { IClientType, JWTPayload } from "@urbancarecommunity/commons/@types";
import {
  CarerRole,
  ClientRole,
  CommonRole,
  Role,
} from "@urbancarecommunity/commons/constants";
import {
  PublicKeyCredentialWithAssertionJSON,
  PublicKeyCredentialWithAttestationJSON,
} from "@github/webauthn-json";
import { getDecodedToken } from "./Token";
import { Tokens } from "@urbancarecommunity/commons/@types";
import { IUserType } from "@urbancarecommunity/commons/@types";
import { IdentityAPI } from "@apiV2";

const AccessTokenStorageKey = "ucc-access-token";
const IdTokenStorageKey = "ucc-id-token";

class AuthService {
  public hasValidAccessToken = () => {
    const decodedToken = this.getDecodedAccessToken();
    if (!decodedToken) {
      return false;
    }

    return moment(decodedToken.exp - ms("30s")).isAfter(moment());
  };

  private reloadAfterRoleChange = (prevRole?: Role, newRole?: Role) => {
    const wasPreviouslyApproved =
      prevRole === CarerRole.ApprovedCarer &&
      newRole !== CarerRole.ApprovedCarer;

    const wasPreviouslyApprovedIndClient =
      prevRole === ClientRole.ApprovedIndividual &&
      newRole !== ClientRole.ApprovedIndividual;

    const wasPreviouslyApprovedOrgClient =
      prevRole === ClientRole.ApprovedOrganisation &&
      newRole !== ClientRole.ApprovedOrganisation;

    const newlyApproved =
      prevRole !== CarerRole.ApprovedCarer &&
      newRole === CarerRole.ApprovedCarer;

    const newlyApprovedIndClient =
      prevRole !== ClientRole.ApprovedIndividual &&
      newRole === ClientRole.ApprovedIndividual;

    const newlyApprovedOrgClient =
      prevRole !== ClientRole.ApprovedOrganisation &&
      newRole === ClientRole.ApprovedOrganisation;

    if (
      wasPreviouslyApprovedIndClient ||
      wasPreviouslyApprovedOrgClient ||
      wasPreviouslyApproved ||
      newlyApproved ||
      newlyApprovedIndClient ||
      newlyApprovedOrgClient
    ) {
      window.location.replace("/");
    }
  };

  public refreshAccessToken = async () => {
    const { data, error } = await IdentityAPI.getToken(
      this.getAccessTokenFromCache()
    );

    if (error?.status === 403 || error?.status === 401) {
      this.clearAllTokens();
      window.location.replace("/auth/login");
      throw new Error("Failed to get access token");
    }

    if (!data) {
      throw new Error("Failed to get access token data");
    }

    const previousRole = this.getDecodedAccessToken()?.role;
    this.setTokens(data);
    const newRole = this.getDecodedAccessToken()?.role;

    this.reloadAfterRoleChange(previousRole, newRole);
  };

  private clearAccessTokenInCache = () => {
    localStorage.removeItem(AccessTokenStorageKey);
  };
  private clearIdTokenInCache = () => {
    localStorage.removeItem(IdTokenStorageKey);
  };
  private getAccessTokenFromCache = () => {
    return localStorage.getItem(AccessTokenStorageKey);
  };

  private clearAllTokens = () => {
    this.clearAccessTokenInCache();
    this.clearIdTokenInCache();
  };

  private getUser = () => {
    const idToken = localStorage.getItem(IdTokenStorageKey);
    return getDecodedToken(idToken);
  };

  private getDecodedAccessToken = (): JWTPayload | undefined => {
    const storedToken = this.getAccessTokenFromCache();
    return getDecodedToken(storedToken);
  };

  public getAuthorizationToken = async () => {
    if (!this.hasValidAccessToken()) {
      await this.refreshAccessToken();
    }

    return this.getAccessTokenFromCache();
  };

  public clearMFATokens = () => {
    const user = this.getDecodedAccessToken();

    if (
      user?.role === CommonRole.MfaRequired ||
      user?.role === CommonRole.MfaSetup
    ) {
      this.clearAccessTokenInCache();
    }
  };

  public isAuthenticatedAsAdmin = () => {
    const user = this.getUser();
    if (!user) {
      return false;
    }

    return user.userType === IUserType.ADMIN;
  };

  public isAuthenticatedAsCarer = () => {
    const user = this.getUser();
    if (!user) {
      return false;
    }

    return user.userType === IUserType.CARER;
  };

  public isAuthenticatedAsClient = () => {
    const user = this.getUser();
    if (!user) {
      return false;
    }

    return user.userType === IUserType.CLIENT;
  };

  public getAuthRole = () => {
    return this.getDecodedAccessToken()?.role;
  };

  public getAuthenticatedUser = () => {
    return this.getUser();
  };
  public getUserId = () => {
    return this.getUser()?.sub;
  };

  public isAuthenticated = () => {
    return !!this.getUser();
  };

  public isLocked = () => {
    const user = this.getUser();
    const accessToken = this.getAccessTokenFromCache();
    return (
      (user && !accessToken) ||
      (user && (this.isMfaCodeRequired() || this.isMfaSetupRequired()))
    );
  };

  public isMfaCodeRequired = () => {
    return (
      this.hasValidAccessToken() &&
      this.getAuthRole() === CommonRole.MfaRequired
    );
  };

  public isMfaSetupRequired = () => {
    return (
      this.hasValidAccessToken() && this.getAuthRole() === CommonRole.MfaSetup
    );
  };

  public getAccessTokenExpiry = () => {
    const decodedToken = this.getDecodedAccessToken();
    if (!decodedToken) {
      return undefined;
    }

    return new Date(decodedToken.exp - ms("30s"));
  };

  public setTokens = (data?: Tokens) => {
    if (!data) {
      return;
    }

    const { accessToken, idToken } = data;
    if (accessToken) {
      localStorage.setItem(AccessTokenStorageKey, accessToken);
    }
    if (idToken) {
      localStorage.setItem(IdTokenStorageKey, idToken);
    }
  };

  public lockAdminPanel = () => {
    localStorage.removeItem(AccessTokenStorageKey);
  };

  public login = async (
    email: string,
    password: string
  ): Promise<APIError | undefined> => {
    const { data, error } = await IdentityAPI.login(email, password);

    if (error || !data) {
      return error;
    }

    this.setTokens(data);
  };

  public logout = async (): Promise<void> => {
    this.clearAllTokens();
    await IdentityAPI.logout();
  };

  public verifyOTPSetup = async (
    code: string
  ): Promise<APIError | undefined> => {
    const { data, error } = await IdentityAPI.verifyOTPSetup(code);

    if (error || !data) {
      return error;
    }

    this.setTokens(data);
  };

  public verifyOTPCode = async (
    code: string
  ): Promise<APIError | undefined> => {
    const { data, error } = await IdentityAPI.verifyOTPCode(code);

    if (error || !data) {
      return error;
    }

    this.setTokens(data);
  };

  public getFidoCredentialOptions = async (): Promise<unknown | undefined> => {
    return IdentityAPI.getFidoCredentialCreationOptions();
  };

  public getFidoAuthOptions = async (): Promise<unknown | undefined> => {
    return IdentityAPI.getFidoCredentialCreationOptions();
  };

  public registerFidoCredential = async (
    credential: PublicKeyCredentialWithAttestationJSON
  ): Promise<unknown | undefined> => {
    const { data, error } = await IdentityAPI.registerFidoCredential(
      credential
    );

    if (error || !data) {
      return error;
    }

    this.setTokens(data);
  };

  public verifyFidoCredentials = async (
    credential: PublicKeyCredentialWithAssertionJSON
  ): Promise<unknown | undefined> => {
    const { data, error } = await IdentityAPI.verifyFidoCredential(credential);

    if (error || !data) {
      return error;
    }

    this.setTokens(data);
  };

  public isIndividualClient = () => {
    const user = this.getUser();

    return user?.clientType === IClientType.INDIVIDUAL;
  };
}

export const authServiceInstance = new AuthService();
