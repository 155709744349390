export const getRandomHundred = (user) => {
  if (!user || !user._id) {
    return "";
  }
  const n = user._id.slice(-2);
  const p = parseInt(n, 16);
  if (p > 99) {
    return p % 100;
  }
  return p;
};

export const getGender = (user) => {
  if (user) {
    return user.gender === "FEMALE" ? "women" : "men";
  }
  return "";
};

export const getAvatar = (user) => {
  if (
    user.avatar &&
    user.avatar.medium &&
    user.avatar.medium.constructor === Object &&
    Object.entries(user.avatar.medium).length !== 0
  ) {
    return user.avatar;
  }
  return null;
};
