import moment from "moment";

export const parseTime = (time) => {
  const t = new Date(time).toLocaleTimeString("en-GB").split(":");
  return `${t[0]}:${t[1]}`;
};
export const parseDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString("en-GB");
};

export const getTimeZoneOffset = () => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const hoursOffset = Math.floor(Math.abs(timeZoneOffset) / 60);
  const minutesOffset = Math.abs(timeZoneOffset) % 60;
  const sign = timeZoneOffset > 0 ? "-" : "+";
  const offSet = `${sign}${hoursOffset}:${String(minutesOffset).padStart(
    2,
    "0"
  )}`;
  return offSet;
};

export const minAllowedDobDate = () => {
  return new Date(
    new Date().getFullYear() - 16,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .slice(0, 10);
};

export const minAllowedDate = (date) => {
  if (date && typeof date === "string")
    return new Date(date).toISOString().slice(0, 10);
  const minYear = date > -1 ? date : 100;
  return new Date(
    new Date().getFullYear() - minYear,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .slice(0, 10);
};

export const maxAllowedDate = (date) => {
  if (date && typeof date === "string")
    return new Date(date).toISOString().slice(0, 10);
  const maxYear = date > -1 ? date : 100;
  return new Date(
    new Date().getFullYear() + maxYear,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .slice(0, 10);
};

export const formatDatePicker = (value, dateFormat = "DD-MM-YYYY") => {
  const date = (value || "").replace(/[^\w\s]/gi, "-");
  moment.locale("en-GB");
  const momentDate = moment(date, dateFormat);
  if (momentDate.isValid() && date.split("-").length === 3) {
    return momentDate.format("YYYY-MM-DD");
  }
  return date;
};

export const validateDate = (value, { minDate, maxDate }) => {
  if (!value) return "Enter Valid Date in DD/MM/YYYY Format.";
  const dashSplit = value.split("-");
  const slashSplit = value.split("/");

  let formattedDate = null;
  if (dashSplit.length === 3) {
    const [year, month, day] = dashSplit.map(Number);
    if (day > 0 && month > 0 && year > 0 && year.toString().length === 4) {
      formattedDate = formatDatePicker(value, "YYYY-MM-DD");
    } else return "Select Valid Date from Calender";
  } else if (slashSplit.length === 3) {
    const [day, month, year] = slashSplit.map(Number);
    if (day > 0 && month > 0 && year > 0 && year.toString().length === 4) {
      formattedDate = formatDatePicker(value, "DD/MM/YYYY");
    } else return "Enter Valid Date in DD/MM/YYYY Format";
  } else {
    return "Enter Valid Date Completely";
  }
  const inputDate = moment(formattedDate);
  if (inputDate < moment(minDate)) return "Please use valid year";
  if (inputDate > moment(maxDate))
    return `Date must be on or before ${moment(maxDate).format("DD/MM/YYYY")}`;
  return true;
};
