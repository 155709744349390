import CFetch from "../fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const addJobRole = (userId: string, details: any) => {
  return CFetch.callAPI(endpoints.role.buildWithParams({ id: userId }), {
    method: "POST",
    body: details,
  });
};

export const getJobRoles = (userId: string) => {
  return CFetch.callAPI(endpoints.role.buildWithParams({ id: userId }));
};

export const getAllJobRoles = (userId: string) => {
  return CFetch.callAPI(endpoints.role.buildWithParams({ id: userId }));
};

export const getAllUnapprovedJobRoles = () => {
  return CFetch.callAPI(endpoints.allRoles.build());
};

export const approveJobRole = (userId: string, roleId: string) => {
  return CFetch.callAPI(
    endpoints.approveRole.buildWithParams({ id: userId, roleId }),
    { method: "PUT" }
  );
};

export const rejectJobRole = (userId: string, roleId: string) => {
  return CFetch.callAPI(
    endpoints.rejectRole.buildWithParams({ id: userId, roleId }),
    { method: "PUT" }
  );
};

export const withdrawJobRole = (userId: string, roleId: string) => {
  return CFetch.callAPI(
    endpoints.withdrawRole.buildWithParams({ id: userId, roleId }),
    { method: "PUT" }
  );
};
