import mongoose from "mongoose";
import { IUploadedDocument } from "../../@types/client/monitoring";

export const UploadedDocumentSchema = new mongoose.Schema<IUploadedDocument>({
  key: String,
  encoding: String,
  mimetype: String,
  originalname: String,
  size: String,
});
