import mongoose from "mongoose";

export const AvatarSchema = new mongoose.Schema({
  xSmall: {
    key: String,
    url: String,
  },
  small: {
    key: String,
    url: String,
  },
  medium: {
    key: String,
    url: String,
  },
  large: {
    key: String,
    url: String,
  },
});
