import { ICarerBankDetails } from "@urbancarecommunity/commons/@types";
import CFetch from "../fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const getBankDetails = (userId: string) => {
  return CFetch.callAPI(endpoints.bankDetails.buildWithParams({ id: userId }));
};

export const createBankDetails = (
  userId: string,
  bankDetails: ICarerBankDetails
) => {
  return CFetch.callAPI(endpoints.bankDetails.buildWithParams({ id: userId }), {
    method: "POST",
    body: bankDetails,
  });
};
