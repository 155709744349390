import { ClientEndpoints } from "@urbancarecommunity/commons/constants";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import CFetch from "../fetch";

const endpoints = new APIEndpoints<typeof ClientEndpoints>(
  ClientEndpoints,
  import.meta.env.VUE_API_CLIENT_URL as string
).get();

export const addServiceUser = (userId: string, details: any) => {
  return CFetch.callAPI(
    endpoints.serviceUser.buildWithParams({
      id: userId,
    }),
    {
      method: "POST",
      body: details,
    }
  );
};

export const updateServiceUser = (userId: string, details: any) => {
  return CFetch.callAPI(
    endpoints.serviceUser.buildWithParams({
      id: userId,
    }),
    {
      method: "PUT",
      body: details,
    }
  );
};

export const getServiceUsers = (userId: string) => {
  return CFetch.callAPI(
    endpoints.serviceUser.buildWithParams({
      id: userId,
    })
  );
};

export const getServiceUser = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.serviceUserId.buildWithParams({
      id: userId,
      serviceUserId,
    })
  );
};

export const deleteServiceUser = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.serviceUser.buildWithParams({
      id: userId,
      serviceUserId,
    }),
    { method: "DELETE" }
  );
};

export const updateServiceUserNotes = (
  userId: string,
  serviceUserId: string,
  note: string
) => {
  return CFetch.callAPI(
    endpoints.serviceUserNote.buildWithParams({
      id: userId,
      serviceUserId,
    }),
    {
      method: "PUT",
      body: note,
    }
  );
};

export const getTaskLists = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.taskList.buildWithParams({
      id: userId,
      serviceUserId,
    })
  );
};

export const getAddresses = (userId: string, serviceUserId: string) => {
  return CFetch.callAPI(
    endpoints.address.buildWithParams({
      id: userId,
      serviceUserId,
    })
  );
};
