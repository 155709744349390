import { APIEndpoint, EndpointDetails } from "./APIEndpoint";

export class APIEndpoints<T extends { [key: string]: EndpointDetails }> {
  private readonly apiEndpoints: { [key in keyof T]: APIEndpoint } =
    {} as typeof this.apiEndpoints;

  constructor(endpoints: T, baseUrl?: string) {
    Object.entries(endpoints).forEach(([key, value]) => {
      this.apiEndpoints[key as keyof T] = new APIEndpoint(value, baseUrl);
    });
  }

  public get = (): typeof this.apiEndpoints => {
    return this.apiEndpoints;
  };
}
