import { ClientEndpoints } from "@urbancarecommunity/commons/constants";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import CFetch from "../fetch";
import {
  ClockInDetails,
  ClockOutDetails,
} from "@urbancarecommunity/commons/@types";

const endpoints = new APIEndpoints<typeof ClientEndpoints>(
  ClientEndpoints,
  import.meta.env.VUE_API_CLIENT_URL as string
).get();

export const clockIn = (
  userId: string,
  {
    bookingId,
    shiftId,
    ...clockInDetails
  }: {
    bookingId: string;
    shiftId: string;
    clockOutDetails: ClockInDetails;
  }
) => {
  return CFetch.callAPI(
    endpoints.bookingShiftClockIn.buildWithParams({
      id: userId,
      bookingId: bookingId,
      shiftId: shiftId,
    }),
    {
      method: "PUT",
      body: clockInDetails,
    }
  );
};

export const clockOut = (
  userId: string,
  {
    bookingId,
    shiftId,
    ...clockOutDetails
  }: {
    bookingId: string;
    shiftId: string;
    clockOutDetails: ClockOutDetails;
  }
) => {
  return CFetch.callAPI(
    endpoints.bookingShiftClockOut.buildWithParams({
      id: userId,
      bookingId: bookingId,
      shiftId: shiftId,
    }),
    {
      method: "PUT",
      body: clockOutDetails,
    }
  );
};

export const carerReviewTasks = (
  userId: string,
  {
    bookingId,
    shiftId,
  }: {
    bookingId: string;
    shiftId: string;
  },
  tasks: string[]
) => {
  return CFetch.callAPI(
    endpoints.bookingShiftCarerReviewTasks.buildWithParams({
      id: userId,
      bookingId: bookingId,
      shiftId: shiftId,
    }),
    {
      method: "PUT",
      body: { tasks },
    }
  );
};
