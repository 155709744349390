import {
  BookingStatus,
  IBookingSchema,
  IUser,
} from "@urbancarecommunity/commons/@types";

export interface SnackbarData {
  title: string;
  buttonText: string;
  route: string;
}

export const OngoingBookingStatuses = [
  BookingStatus.waitingForReview,
  BookingStatus.clockInPending,
  BookingStatus.clockOutPending,
  BookingStatus.paymentCompleted,
];
export const UpcomingBookingStatuses = [
  BookingStatus.clockInPending,
  BookingStatus.clockOutPending,
  BookingStatus.paymentCompleted,
  BookingStatus.paymentPending,
  BookingStatus.shiftWaiting,
];

export type BookingFilterState = Record<keyof IBookingSchema, unknown>;

export interface RootState {
  applicationVersion: string | undefined;
  user: IUser | undefined;
  snackbars: Array<SnackbarData>;
  // bookingFilters: Partial<Pick<IBookingSchema, "status">>;
  bookingFilters: Partial<BookingFilterState>;
}

const initialState = (): RootState => ({
  applicationVersion: undefined,
  user: undefined,

  bookingFilters: {
    status: ["ongoing"],
  },

  snackbars: [],
});

export default initialState;
