import CFetch from "../fetch";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { CarerEndpoints } from "@urbancarecommunity/commons/constants";
import {
  IAddAvailability,
  IDeleteAvailability,
} from "@urbancarecommunity/commons/@types";

const endpoints = new APIEndpoints<typeof CarerEndpoints>(
  CarerEndpoints,
  import.meta.env.VUE_API_CARER_URL as string
).get();

export const addAvailability = (userId: string, details: IAddAvailability) => {
  return CFetch.callAPI(
    endpoints.availabilities.buildWithParams({ id: userId }),
    {
      method: "POST",
      body: details,
    }
  );
};

export const deleteAvailability = (
  userId: string,
  details: IDeleteAvailability
) => {
  return CFetch.callAPI(
    endpoints.availabilities.buildWithParams({
      id: userId,
    }),
    { method: "DELETE", body: details }
  );
};
